import clsx from "clsx";
import common from "../../data/common.json";

export function Banners() {
  return (
    <div className={clsx("border-t", "border-t-blue-200", "w-full", "bg-white", "p-8")}>
      <div className={clsx("flex", "flex-wrap", "items-center", "justify-center", "space-x-4")}>
        {common.footer.banners.map((banner, index) => (
          <div key={index} className={clsx("min-w-0")}>
            <div className={clsx("w-48", "m-8")}>
              <a href={banner.url} target="_blank" rel="noreferrer noopener" className={clsx("block", "w-full")}>
                <img
                  src={banner.image}
                  alt={banner.name}
                  width={192}
                  height={55}
                  draggable={false}
                  className="object-contain"
                />
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
