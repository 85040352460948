import { getApps, initializeApp } from "firebase/app";

export function initFirebase() {
  if (getApps().length === 0) {
    initializeApp({
      apiKey: "AIzaSyBskUHJ_ce-mpe4TUHQkHXQKsBod6LgmhE",
      authDomain: "bandaijima-okama.firebaseapp.com",
      projectId: "bandaijima-okama",
      storageBucket: "bandaijima-okama.appspot.com",
      messagingSenderId: "1048543243159",
      appId: "1:1048543243159:web:861cc1f3253ad47a9f72d2",
      measurementId: "G-PM585NKBM7",
    });
  }
}
