import { Link } from "@remix-run/react";
import clsx from "clsx";

interface RoutesProps {
  menu: {
    name: string;
    url: string;
    external?: boolean;
  }[];
  name: string;
}

export function Routes({ menu, name }: RoutesProps) {
  return (
    <div className={clsx("mb-8", "text-white")}>
      <h2 className={clsx("text-lg", "font-bold", "leading-relaxed")}>{name}</h2>
      <ul className={clsx("flex", "flex-col")}>
        {menu.map((item, index) => (
          <li key={index} className={clsx("mt-4")}>
            {item.external ? (
              <a
                href={item.url}
                className="button button--plain button--monochrome button--no-underline"
                target="_blank"
                rel="noreferrer noopener"
              >
                <span className="button__content">{item.name}</span>
              </a>
            ) : (
              <Link to={item.url} className="button button--plain button--monochrome button--no-underline">
                <span className="button__content">{item.name}</span>
              </Link>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}
