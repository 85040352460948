import { Link } from "@remix-run/react";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { TitleLogo } from "../TitleLogo";
import { Narrow } from "./Narrow";
import { Wide } from "./Wide";

export function ApplicationHeader() {
  const [active, setActive] = useState(false);

  const toggleHeaderStyles = () => {
    if (window.scrollY > 0) {
      setActive(true);
    } else {
      setActive(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleHeaderStyles, false);
    return () => {
      window.removeEventListener("scroll", toggleHeaderStyles, false);
    };
  }, []);

  return (
    <header
      role="banner"
      className={clsx(
        "sticky",
        "top-0",
        "left-0",
        "w-full",
        "z-30",
        "border-b",
        active ? "border-b-white" : "border-b-blue-200"
      )}
    >
      <div className="relative">
        <div
          className={clsx(
            "absolute",
            "top-0",
            "left-0",
            "w-full",
            "h-full",
            "transition-all",
            "duration-150",
            "ease-in",
            "opacity-100",
            "bg-white",
            active ? "shadow-elevation-low" : "shadow-none"
          )}
        />

        <div
          className={clsx(
            "relative",
            "px-8",
            "min-h-[var(--small-header-height)]",
            "z-[1]",
            "flex",
            "flex-col",
            "justify-center",
            "md:min-h-[var(--medium-header-height)]"
          )}
        >
          <div className="flex items-center justify-between">
            <div>
              <Link to="/" className="block">
                <span className="visually-hidden">ホームページ</span>
                <TitleLogo
                  className={clsx(
                    `w-[80px]`,
                    `h-[44.3103448px]`, // 80 * 80 * 0.55387931px
                    `md:w-[122px]`,
                    `md:h-[67.57327582px]` // 122 * 0.55387931
                  )}
                />
              </Link>
            </div>

            <Wide />
            <Narrow />
          </div>
        </div>
      </div>
    </header>
  );
}
