import { Link } from "@remix-run/react";
import clsx from "clsx";
import common from "../../data/common.json";

export function Header() {
  return (
    <div className={clsx("grid", "grid-cols-1", "gap-2", "text-white")}>
      <div className={clsx("text-xl", "font-bold", "leading-loose")}>
        {common.website.info.manager.name}
        <br />
        {common.website.info.charge.name}
      </div>
      <div className="space-y-2">
        <p className="text-sm leading-normal">
          {`〒${common.website.info.manager.postal_code}`}
          <br />
          {common.website.info.manager.address}
        </p>
        <p className="text-sm leading-normal">
          メール: 公式HP
          <Link to="/contact/inquiry" className="button button--plain button--monochrome">
            <span className="button__content">お問い合わせ</span>
          </Link>
          からご利用下さい
        </p>
      </div>
    </div>
  );
}
