import { Link } from "@remix-run/react";
import clsx from "clsx";
import { Fragment, useId, useState } from "react";
import common from "../../data/common.json";

export function Narrow() {
  const [open, setOpen] = useState(false);
  const id = useId();

  return (
    <Fragment>
      <button
        type="button"
        aria-label={`メニューを${open ? "閉じる" : "開く"}`}
        aria-haspopup="dialog"
        aria-expanded={open ? "true" : "false"}
        aria-controls={id}
        onClick={() => setOpen((open) => !open)}
        className={clsx(
          "relative",
          "w-[48px]",
          "h-[48px]",
          "p-[11px]",
          "border",
          "border-blue-600",
          "rounded-full",
          "outline-none",
          "z-10",
          "block",
          "lg:hidden",
          "!pointer-events-auto"
        )}
      >
        <span
          className={clsx(
            "block",
            "bg-blue-600",
            "w-[26px]",
            "h-[2px]",
            "my-[5px]",
            "mx-0",
            "origin-[45%_50%]",
            "transition-all",
            "duration-100",
            open ? ["translate-y-[6px]", "rotate-45"] : ["translate-y-0", "rotate-0"]
          )}
        />
        <span
          className={clsx(
            "block",
            "bg-blue-600",
            "w-[26px]",
            "h-[2px]",
            "my-[5px]",
            "mx-0",
            "origin-[45%_50%]",
            "transition-all",
            "duration-100",
            open ? "opacity-0" : "opacity-100"
          )}
        />
        <span
          className={clsx(
            "block",
            "bg-blue-600",
            "w-[26px]",
            "h-[2px]",
            "my-[5px]",
            "mx-0",
            "origin-[45%_50%]",
            "transition-all",
            "duration-100",
            open ? ["-translate-y-[6px]", "-rotate-45"] : ["translate-y-0", "rotate-0"]
          )}
        />
      </button>

      <div
        role="dialog"
        id={id}
        tabIndex={-1}
        className={clsx(
          "bg-white",
          "bg-opacity-90",
          "fixed",
          "inset-0",
          "block",
          "lg:hidden",
          "transition-opacity",
          "duration-150",
          open ? ["opacity-100", "pointer-events-auto"] : ["opacity-0", "pointer-events-none"]
        )}
        data-outline="full"
      >
        <div className={clsx("pt-20", "px-8", "w-full", "h-full", "flex", "justify-end")}>
          <ul
            itemScope
            itemType="http://schema.org/SiteNavigationElement"
            className={clsx("relative", "flex", "flex-col", "justify-center")}
          >
            {common.header.menus.map((menu, index) => (
              <li
                key={index}
                itemProp="name"
                className={clsx(
                  "block",
                  "mb-2",
                  "pr-12",
                  "transition-transform",
                  "duration-100",
                  open ? ["opacity-100", "translate-y-0"] : ["opacity-0", "translate-y-12"]
                )}
              >
                <Link
                  to={menu.url}
                  className={clsx("block", "hover:underline", "focus:underline")}
                  itemProp="url"
                  aria-label={menu.name}
                  onClick={() => setOpen(false)}
                >
                  <span className={clsx("font-sans", "font-black", "text-3xl", "leading-normal", "text-gray-900")}>
                    {menu.name}
                  </span>
                </Link>
              </li>
            ))}

            <li itemProp="name" className="mt-4">
              <Link to="/vacancies" className="button button--large button--secondary" onClick={() => setOpen(false)}>
                <span className="button__backdrop" />
                <span className="button__content">会場の予約状況</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </Fragment>
  );
}
