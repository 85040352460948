import { Link } from "@remix-run/react";
import clsx from "clsx";
import common from "../../data/common.json";

export function Wide() {
  return (
    <ul
      className={clsx("hidden", "lg:flex", "lg:items-center", "lg:justify-end", "text-inherit")}
      itemScope
      itemType="http://schema.org/SiteNavigationElement"
    >
      {common.header.menus.map((menu, index) => (
        <li key={index} itemProp="name">
          <Link to={menu.url} itemProp="url" className={clsx("block", "p-2", "text-sm")}>
            {menu.name}
          </Link>
        </li>
      ))}

      <li itemProp="name" className="ml-4">
        <Link to="/vacancies" className="button button--secondary">
          <span className="button__backdrop" />
          <span className="button__content">会場の予約状況</span>
        </Link>
      </li>
    </ul>
  );
}
