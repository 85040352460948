import type { ReactNode } from "react";
import { Fragment } from "react";
import { ApplicationFooter } from "../ApplicationFooter";
import { ApplicationHeader } from "../ApplicationHeader";

export function ApplicationLayout({ children }: { children: ReactNode }) {
  return (
    <Fragment>
      <a className="visually-hidden" href="#MainContent">
        Skip to content
      </a>

      <ApplicationHeader />

      <main role="main" id="MainContent" className={"flex-grow"}>
        {children}
      </main>

      <ApplicationFooter />
    </Fragment>
  );
}
