import clsx from "clsx";
import dayjs from "dayjs";
import common from "../../data/common.json";
import { Banners } from "./Banners";
import { Charge } from "./Charge";
import { Header } from "./Header";
import { Routes } from "./Routes";

export function ApplicationFooter() {
  return (
    <footer role="contentinfo" className="relative">
      <Banners />
      <div className={clsx("w-full", "bg-blue-600", "pt-20", "pb-16", "px-6")}>
        <div
          className={clsx("max-w-8xl", "mx-auto", "gap-8", "grid", "grid-cols-4", "md:grid-cols-8", "lg:grid-cols-12")}
        >
          <div className={clsx("w-full", "col-span-4", "sm:w-auto", "md:col-span-3", "md:row-span-2", "lg:col-span-4")}>
            <div className="space-y-8">
              <Header />
              <Charge />
            </div>
          </div>

          <div className={clsx("hidden", "md:block", "md:col-span-1", "md:row-span-2", "lg:col-span-2")} />

          {common.footer.menus.map((menu, index) => (
            <div key={index} className="col-span-2">
              <Routes name={menu.name} menu={menu.items} />
            </div>
          ))}
        </div>
      </div>

      <div className={clsx("bg-white", "pb-2", "pt-2", "text-center", "text-gray-500", "text-sm")}>
        {`© ${dayjs().locale("ja").format("YYYY")} ${common.website.info.manager.name} All rights reserved.`}
      </div>
    </footer>
  );
}
