import clsx from "clsx";
import { Fragment } from "react";
import common from "../../data/common.json";

const Space = () => <Fragment>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Fragment>;

export function Charge() {
  return (
    <div className={clsx("border border-white", "rounded-xl", "text-sm", "text-white", "p-4")}>
      <div className={clsx("space-y-2")}>
        <p className={clsx("text-sm")}>
          {`設置者: ${common.website.info.installer.name}`}
          <br />
          <Space />
          {`連絡先: ${common.website.info.installer.tel}`}
        </p>
        <p className={clsx("text-sm")}>
          {`設置者: ${common.website.info.manager.name}`}
          <br />
          <Space />
          {`連絡先: ${common.website.info.manager.tel}`}
          <br />
          <Space />
          {`FAX: ${common.website.info.manager.fax}`}
        </p>
      </div>
    </div>
  );
}
