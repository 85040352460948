import { Links, LiveReload, Meta, Scripts, ScrollRestoration } from "@remix-run/react";
import type { ReactNode } from "react";
import type { WebsiteEnv } from "@/lib/env.server";
import common from "@/data/common.json";

export interface DocumentProps {
  websiteEnv: WebsiteEnv;
  title?: string;
  canonical?: string;
  noindex?: boolean;
  children?: ReactNode;
  isHome?: boolean;
}

export function Document({ websiteEnv, canonical, title, noindex = false, isHome = false, children }: DocumentProps) {
  return (
    <html lang="ja" prefix="og:http://ogp.me/ns#" data-website-package-env={websiteEnv}>
      <head>
        <Meta />
        {canonical && <link rel="canonical" href={canonical} />}
        <Links />
        {noindex && <meta name="robots" content="noindex,nofollow" />}
        {title && <title>{`${title} | ${common.meta.title}`}</title>}
      </head>

      <body id={isHome ? "home" : undefined} className="font-sans">
        {children}
        <Scripts />
        <ScrollRestoration />
        {process.env.NODE_ENV === "development" && <LiveReload />}
      </body>
    </html>
  );
}
