import clsx from "clsx";

export type TitleLogoProps = {
  width?: number;
  className?: string;
};

export function TitleLogo({ width, className }: TitleLogoProps) {
  return (
    <svg
      width={width}
      height={width ? width * 0.55387931 : undefined}
      viewBox="0 0 464 257"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx("block", "w-full", className)}
    >
      <path
        d="M230.51 149.33C230.51 148 229.71 147.09 228.15 147.09H219.41C218.08 147.09 217.34 147.56 216.52 148.36C215.31 149.51 213.69 150.55 211.53 151.08C211.03 151.2 210.7 151.61 210.7 152.2C210.7 152.79 211.2 153.44 211.79 153.44C213.33 153.44 216.43 151.34 217.73 150.25C218.38 149.72 218.94 149.48 219.56 149.48H226.88C227.2 149.48 227.35 149.66 227.35 149.86C227.35 151.16 221.44 159.04 210.58 159.31C209.87 159.34 209.28 159.63 209.28 160.55C209.28 161.17 209.72 161.76 210.43 161.76C222.8 161.5 230.51 151.93 230.51 149.33Z"
        fill="#00A161"
      />
      <path
        d="M191.14 163.95H171.41C171 163.95 170.67 163.65 170.67 163.24V161.82H186.32C186.7 161.82 187.18 161.44 187.18 160.85C187.18 160.29 186.71 159.91 186.32 159.91H170.67V157.7H187.35C188.59 157.7 189.56 156.73 189.56 155.49V150.12C189.56 148.85 188.59 147.88 187.35 147.88H178.96C178.99 147.76 179.02 147.67 179.02 147.56C179.02 147.06 178.58 146.59 177.69 146.59C177.16 146.59 176.77 146.8 176.63 147.18L176.33 147.89H170.31C169.07 147.89 168.1 148.86 168.1 150.13V163.95C168.1 165.19 169.07 166.16 170.31 166.16H174.71V171.06H169.99C169.58 171.06 169.28 170.74 169.28 170.35V169.08C169.28 168.61 168.78 168.22 168.04 168.22C167.3 168.22 166.8 168.6 166.8 169.08V170.85C166.8 172.06 167.77 173.04 169.01 173.04H182.62C183.86 173.04 184.83 172.07 184.83 170.85V169.08C184.83 168.61 184.33 168.22 183.62 168.22C182.88 168.22 182.38 168.6 182.38 169.08V170.35C182.38 170.73 182.06 171.06 181.67 171.06H177.27V166.16H190.06C190.47 166.16 190.77 166.48 190.77 166.9V168.14C190.77 170.3 189.94 171.03 188.17 171.03H187.61C187.23 171.03 186.81 171.44 186.81 172.24C186.81 172.98 187.25 173.48 187.75 173.48H188.78C192.06 173.48 193.33 171.68 193.33 168.43V166.19C193.36 164.92 192.38 163.95 191.14 163.95ZM170.68 150.37C170.68 149.96 171 149.63 171.42 149.63H186.27C186.68 149.63 187.01 149.95 187.01 150.37V151.85H170.68V150.37ZM170.68 153.67H187.01V155.15C187.01 155.56 186.69 155.86 186.27 155.86H170.68V153.67Z"
        fill="#00A161"
      />
      <path
        d="M161.82 153.35L153.43 154.09C153.22 152.08 153.11 149.84 153.08 147.3C153.08 146.8 152.55 146.24 151.78 146.24C150.92 146.24 150.42 146.83 150.42 147.3C150.45 149.87 150.57 152.2 150.77 154.3L145.22 154.77C144.78 154.8 144.3 155.27 144.3 155.98C144.3 156.78 144.83 157.31 145.33 157.28L151.09 156.78C152.57 165.99 156.26 170.33 160.69 172.4C160.81 172.46 160.96 172.49 161.1 172.49C161.75 172.49 162.49 171.93 162.49 171.1C162.49 170.6 162.28 170.16 161.84 169.95C158.15 168.18 155.02 164.69 153.72 156.54L161.96 155.77C162.4 155.74 162.85 155.27 162.85 154.56C162.85 153.82 162.29 153.35 161.82 153.35Z"
        fill="#00A161"
      />
      <path
        d="M128.59 155.74H115.3C115.51 153.88 115.62 151.84 115.68 149.63H127.34C127.84 149.63 128.4 149.1 128.4 148.36C128.4 147.59 127.84 147.09 127.34 147.09H106.91C106.38 147.09 105.82 147.59 105.82 148.36C105.82 149.1 106.38 149.63 106.91 149.63H112.99C112.84 159.96 110.92 165.93 105.64 169.74C105.37 169.95 105.2 170.24 105.2 170.66C105.2 171.34 105.88 172.11 106.56 172.11C106.74 172.11 106.91 172.05 107.09 171.93C111.43 168.71 113.85 164.4 114.94 158.29H127.37C127.75 158.29 128.08 158.61 128.08 159.03V166.15C128.08 169.31 126.51 170.58 123.56 170.58H121.29C120.76 170.58 120.17 171.11 120.17 171.85C120.17 172.68 120.76 173.21 121.29 173.21H123.74C127.43 173.21 130.8 171.7 130.8 166.54V157.98C130.81 156.71 129.83 155.74 128.59 155.74Z"
        fill="#00A161"
      />
      <path
        d="M342.5 156.77H354.64C355.88 156.77 356.83 155.85 356.83 154.67V149.15C356.83 148 355.86 147.08 354.64 147.08H342.53C341.35 147.08 340.37 147.97 340.37 149.15V154.67C340.37 155.86 341.35 156.77 342.5 156.77ZM342.83 149.57C342.83 149.19 343.13 148.89 343.51 148.89H353.73C354.11 148.89 354.41 149.19 354.41 149.57V151.02H342.83V149.57V149.57ZM342.83 152.79H354.41V154.27C354.41 154.65 354.11 154.95 353.73 154.95H343.51C343.13 154.95 342.83 154.65 342.83 154.27V152.79V152.79Z"
        fill="#00A161"
      />
      <path
        d="M141.74 157.19C143.13 155.06 144.04 152.41 144.37 149.48C144.37 148.98 143.84 148.36 143.07 148.36C142.3 148.36 141.77 148.92 141.71 149.45C141.12 154.12 138.88 157.51 136.42 158.81C136.04 159.11 135.8 159.52 135.8 159.93C135.8 160.79 136.54 161.29 137.16 161.29C137.96 161.29 139.58 159.75 140.08 159.22V170.82C140.08 171.29 140.61 171.85 141.35 171.85C142.09 171.85 142.62 171.29 142.62 170.82V158.12C142.62 157.72 142.27 157.31 141.74 157.19Z"
        fill="#00A161"
      />
      <path
        d="M292.59 149.27H286.68C286.77 148.65 286.83 148.03 286.83 147.35C286.83 146.88 286.27 146.29 285.53 146.29C284.82 146.29 284.35 146.76 284.32 147.23C284.2 150.6 283.26 153.49 281.43 155.17C281.25 155.38 281.16 155.64 281.16 155.94C281.16 156.56 281.57 157.21 282.49 157.21C282.84 157.21 283.11 157.09 283.29 156.91C284.77 155.4 285.68 153.72 286.24 151.71H291.64C292.05 151.71 292.35 152.01 292.35 152.42V168.1C292.35 169.72 291.41 170.7 289.72 170.7H288.42C287.95 170.7 287.42 171.2 287.42 171.91C287.42 172.71 287.95 173.18 288.45 173.18H290.34C293.15 173.18 294.8 171.41 294.8 168.6V151.49C294.8 150.25 293.83 149.27 292.59 149.27Z"
        fill="#00A161"
      />
      <path
        d="M337.84 167.88C337.69 167.88 337.54 167.91 337.43 168C336.96 168.3 336.43 168.56 335.87 168.8V155.25H337.64C338.14 155.25 338.67 154.78 338.67 154.04C338.67 153.33 338.14 152.83 337.64 152.83H335.86V149.46C335.86 148.99 335.36 148.43 334.65 148.43C333.94 148.43 333.47 148.99 333.47 149.46V152.83H331.67C331.17 152.83 330.64 153.33 330.64 154.04C330.64 154.78 331.17 155.25 331.67 155.25H333.47V169.6C332.82 169.78 332.17 169.9 331.49 170.01C331.05 170.07 330.63 170.48 330.63 171.1C330.63 172.04 331.31 172.34 331.66 172.34C331.69 172.34 331.69 172.34 331.72 172.34C334.2 171.9 336.68 171.16 338.45 170.13C338.77 169.92 338.92 169.51 338.92 169.13C338.93 168.35 338.34 167.88 337.84 167.88Z"
        fill="#00A161"
      />
      <path
        d="M304.05 151.52C304.05 151.11 304.37 150.81 304.79 150.81H322.63C323.16 150.81 323.75 150.28 323.75 149.48C323.75 148.71 323.16 148.18 322.63 148.18H315.54V147.29C315.54 146.76 315.01 146.23 314.24 146.23C313.44 146.23 312.91 146.76 312.91 147.29V148.18H303.73C302.49 148.18 301.52 149.15 301.52 150.39V161.37C301.52 162.79 301.14 164.2 300.58 165.27C300.49 165.45 300.46 165.59 300.46 165.77C300.46 166.39 300.99 166.98 301.88 166.98C302.38 166.98 302.77 166.71 302.91 166.42C303.62 165.12 304.06 163.11 304.06 161.67V151.52H304.05Z"
        fill="#00A161"
      />
      <path
        d="M288.41 161.37C288.41 160.28 287.53 159.4 286.44 159.4C285.35 159.4 284.47 160.28 284.47 161.37C284.47 162.46 285.35 163.34 286.44 163.34C287.53 163.34 288.41 162.46 288.41 161.37Z"
        fill="#00A161"
      />
      <path
        d="M355.41 163.03H345.28C345.78 162.38 346.2 161.67 346.46 160.9H355.23C355.64 160.9 356.12 160.49 356.12 159.87C356.12 159.28 355.65 158.84 355.23 158.84H342C341.59 158.84 341.11 159.28 341.11 159.87C341.11 160.49 341.58 160.9 342 160.9H344.36C343.65 162.58 342 163.85 340.14 164.44C339.87 164.59 339.64 164.82 339.64 165.21C339.64 165.62 339.91 166.24 340.56 166.24C341.24 166.24 342.69 165.38 343.22 165H346.76C345.7 166.86 343.42 168.4 340.88 168.96C340.56 169.02 340.26 169.28 340.26 169.73C340.26 170.35 340.76 170.76 341.26 170.76C341.32 170.76 341.41 170.76 341.47 170.73C344.54 169.87 347.52 167.54 348.82 165H351.8C350.83 167.86 348.14 170.32 344.65 171.08C344.33 171.14 344.06 171.43 344.06 171.85C344.06 172.47 344.5 172.88 345.06 172.88C345.09 172.88 345.09 172.88 345.12 172.88C348.93 172.26 352.53 169.01 353.8 165H354.74C355.15 165 355.48 165.32 355.48 165.74V169.17C355.48 170.44 354.86 171.15 353.71 171.15H352.97C352.56 171.15 352.14 171.56 352.14 172.15C352.14 172.77 352.61 173.18 353.03 173.18H354.06C356.25 173.18 357.6 171.59 357.6 169.19V165.23C357.62 164.01 356.64 163.03 355.41 163.03Z"
        fill="#00A161"
      />
      <path
        d="M322.74 161.47C322.59 161.06 322.18 160.7 321.53 160.7C320.7 160.7 320.08 161.23 320.08 161.82C320.08 161.91 320.11 162.03 320.14 162.12L322.21 168.26C319.35 169.88 315.68 170.18 309.28 170.24L313.56 155.74C313.59 155.65 313.59 155.56 313.59 155.47C313.59 154.85 312.97 154.2 312.05 154.2C311.58 154.2 311.02 154.5 310.87 155.03L306.41 170.24H304.85C304.32 170.24 303.73 170.77 303.73 171.54C303.73 172.31 304.32 172.87 304.85 172.87C314.68 172.84 319.64 172.69 323.01 170.63L323.63 172.49C323.78 172.9 324.19 173.26 324.87 173.26C325.67 173.26 326.29 172.7 326.29 172.11C326.29 172.02 326.26 171.9 326.23 171.81L322.74 161.47Z"
        fill="#00A161"
      />
      <path
        d="M233.49 157.16H226.96C225.51 157.16 224.42 158.08 223.62 158.99C221.76 161.18 218.39 163.15 214.64 163.63C214.26 163.69 213.81 164.13 213.81 164.69C213.81 165.37 214.31 165.99 214.9 165.99C214.93 165.99 214.99 165.99 215.02 165.99C218.92 165.4 222.9 163.63 225.68 160.17C225.95 159.85 226.42 159.58 226.83 159.58H232.5C232.85 159.58 233.06 159.79 233.06 160.08C233.06 160.14 233.06 160.23 233.03 160.32C231.88 164.9 228.33 170.83 211.86 170.83C211.33 170.83 210.86 171.27 210.86 171.95C210.86 172.51 211.24 173.19 211.92 173.19C227.75 173.19 233.95 168.05 235.75 160.32C235.84 159.97 235.87 159.61 235.87 159.35C235.89 157.9 234.91 157.16 233.49 157.16Z"
        fill="#00A161"
      />
      <path
        d="M277.44 149.54H275.28L275.93 147.83C275.96 147.74 275.99 147.62 275.99 147.53C275.99 147 275.46 146.5 274.63 146.5C274.19 146.5 273.66 146.71 273.48 147.21L272.59 149.54H271.97C270.73 149.54 269.76 150.48 269.76 151.72V165.75C269.76 166.99 270.73 167.94 271.97 167.94H277.43C278.67 167.94 279.64 167 279.64 165.75V151.72C279.66 150.48 278.68 149.54 277.44 149.54ZM277.2 164.81C277.2 165.22 276.9 165.52 276.49 165.52H272.92C272.51 165.52 272.21 165.22 272.21 164.81V159.58H277.2V164.81V164.81ZM277.2 157.19H272.21V152.67C272.21 152.23 272.51 151.96 272.92 151.96H276.49C276.9 151.96 277.2 152.23 277.2 152.67V157.19V157.19Z"
        fill="#00A161"
      />
      <path
        d="M262.05 147.09H242.86C241.62 147.09 240.65 148.06 240.65 149.3V170.62C240.65 171.83 241.62 172.81 242.86 172.81H262.05C263.29 172.81 264.26 171.84 264.26 170.62V149.3C264.27 148.06 263.29 147.09 262.05 147.09ZM261.64 169.8C261.64 170.21 261.34 170.51 260.93 170.51H243.98C243.57 170.51 243.27 170.21 243.27 169.8V165.02H261.64V169.8V169.8ZM261.64 162.68H243.27V157.19H261.64V162.68ZM261.64 154.88H243.27V150.13C243.27 149.72 243.57 149.42 243.98 149.42H260.93C261.34 149.42 261.64 149.72 261.64 150.13V154.88V154.88Z"
        fill="#00A161"
      />
      <path
        d="M159.62 150.97C160.53 150.97 161.26 150.24 161.26 149.33C161.26 148.42 160.53 147.69 159.62 147.69C158.71 147.69 157.98 148.42 157.98 149.33C157.97 150.23 158.71 150.97 159.62 150.97Z"
        fill="#00A161"
      />
      <path
        d="M217.31 156.39C218.216 156.39 218.95 155.656 218.95 154.75C218.95 153.844 218.216 153.11 217.31 153.11C216.404 153.11 215.67 153.844 215.67 154.75C215.67 155.656 216.404 156.39 217.31 156.39Z"
        fill="#00A161"
      />
      <path
        d="M224.13 167.86C225.036 167.86 225.77 167.126 225.77 166.22C225.77 165.314 225.036 164.58 224.13 164.58C223.224 164.58 222.49 165.314 222.49 166.22C222.49 167.126 223.224 167.86 224.13 167.86Z"
        fill="#00A161"
      />
      <path
        d="M188.76 212.59C190.09 212.59 191.57 211.19 191.57 209.19C191.57 207.19 190.09 205.86 188.76 205.86H182.33H161.48V191.89C161.48 190.56 160.08 189.01 157.93 189.01C157.83 189.01 157.73 189.03 157.63 189.04C157.53 189.03 157.44 189.01 157.33 189.01C155.19 189.01 153.78 190.56 153.78 191.89V205.86H132.93H126.5C125.17 205.86 123.69 207.19 123.69 209.19C123.69 211.19 125.17 212.59 126.5 212.59H132.93H153.63C152.86 221.39 149.82 229.23 145.12 235.47C145 235.63 144.87 235.8 144.74 235.96C144.27 236.56 143.79 237.14 143.3 237.71C143.19 237.84 143.07 237.97 142.96 238.1C138.42 243.15 132.67 246.9 126.13 248.89C124.87 249.26 124.21 250.81 124.21 251.85C124.21 253.99 125.91 255.62 127.61 255.62C127.83 255.62 128.13 255.55 128.35 255.47C142.1 251.18 152.67 240.09 157.63 226.27C162.58 240.1 173.16 251.19 186.91 255.47C187.13 255.54 187.43 255.62 187.65 255.62C189.35 255.62 191.05 253.99 191.05 251.85C191.05 250.81 190.38 249.26 189.13 248.89C182.59 246.9 176.84 243.16 172.3 238.1C172.18 237.97 172.07 237.84 171.96 237.71C171.46 237.14 170.98 236.56 170.51 235.96C170.38 235.8 170.26 235.64 170.13 235.47C165.43 229.23 162.38 221.39 161.62 212.59H182.32H188.76Z"
        fill="#63686E"
      />
      <path
        d="M231.62 200.46H221.93C222.15 198.46 222.37 196.39 222.37 194.47C222.37 193.21 221.11 192.18 219.12 192.18C217.13 192.18 215.79 193.14 215.72 194.47C215.65 196.32 215.5 198.31 215.28 200.46H206.41C205.08 200.46 204.12 201.72 204.12 203.64C204.12 205.56 205.08 206.82 206.41 206.82H214.62C213.44 216.06 211.44 226.56 209.22 235.88C209.15 236.03 209.15 236.18 209.15 236.32C209.15 237.95 211.15 238.98 212.99 238.98C214.32 238.98 215.28 238.31 215.5 237.35C218.09 227.74 220.08 216.65 221.27 206.82H231.32C239.6 206.82 243.3 212 243.3 220.72V236.62C243.3 245.93 240.34 249.71 231.03 249.71H227.11C225.78 249.71 224.97 251.04 224.97 253.04C224.97 255.11 225.78 256.44 227.11 256.44H231.1C244.56 256.44 249.95 250.82 249.95 236.63V220.96C249.96 207.56 243.97 200.46 231.62 200.46Z"
        fill="#63686E"
      />
      <path
        d="M261.04 195.44C256.19 195.44 252.26 199.37 252.26 204.22C252.26 209.07 256.19 213 261.04 213C265.89 213 269.82 209.07 269.82 204.22C269.83 199.38 265.9 195.44 261.04 195.44Z"
        fill="#63686E"
      />
      <path
        d="M335.04 241.16C333.56 240.28 322.12 233.52 314.31 231.33V220.06H323.7C324.88 220.06 325.77 218.95 325.77 217.18C325.77 215.41 324.88 214.22 323.7 214.22H314.31V202.61H323.7C324.88 202.61 325.77 201.5 325.77 199.73C325.77 197.96 324.88 196.85 323.7 196.85H314.31V193.45C314.31 192.12 313.05 191.16 311.06 191.16C309.06 191.16 307.81 192.12 307.81 193.45V196.85H288.59C287.41 196.85 286.52 197.96 286.52 199.73C286.52 201.5 287.41 202.61 288.59 202.61H307.81V214.22H288.51C287.4 214.22 286.51 215.33 286.51 217.03C286.51 218.66 287.4 219.77 288.51 219.77H307.81V229.45C302.78 228.05 297.46 227.16 291.69 227.16C278.75 227.16 272.1 233.96 272.1 241.58C272.1 249.2 277.35 256.51 291.99 256.51C309.28 256.51 314.08 247.8 314.31 238.51C316.22 239.19 318.44 240.16 320.72 241.26C322.44 242.12 324.19 243.01 325.98 243.94C328.03 245.04 329.96 246.13 331.52 247.06C332.07 247.39 332.68 247.55 333.28 247.55C334.45 247.55 335.59 246.95 336.23 245.88C337.2 244.23 336.67 242.13 335.04 241.16ZM307.8 235.87C307.8 243.93 305.29 250.66 291.98 250.66C282.22 250.66 278.89 246 278.89 241.42C278.89 236.69 284.06 232.99 292.05 232.99C297.59 232.99 302.77 233.95 307.8 235.65V235.87V235.87Z"
        fill="#63686E"
      />
      <path
        d="M385.24 45.14C384.8 43.07 383.24 41.4099 381.19 40.8499C330.85 27.1199 278.49 19.6899 231.57 19.6899C184.64 19.6899 132.28 27.1199 81.9498 40.8499C79.9098 41.4099 78.3497 43.07 77.8997 45.14L63.4597 112.5C62.6997 116.03 65.3898 119.36 68.9998 119.36H183.14C186.05 119.36 188.48 117.16 188.78 114.27L192.9 73.9899C193.2 71.0999 195.63 68.9 198.54 68.9H264.61C267.52 68.9 269.95 71.0999 270.25 73.9899L274.37 114.27C274.67 117.16 277.1 119.36 280.01 119.36H394.14C397.75 119.36 400.44 116.03 399.68 112.5L385.24 45.14ZM167.31 68.58C161.65 68.58 157.07 63.99 157.07 58.34C157.07 52.68 161.66 48.0999 167.31 48.0999C172.97 48.0999 177.55 52.69 177.55 58.34C177.56 63.99 172.97 68.58 167.31 68.58ZM295.82 68.58C290.16 68.58 285.58 63.99 285.58 58.34C285.58 52.68 290.17 48.0999 295.82 48.0999C301.47 48.0999 306.06 52.69 306.06 58.34C306.06 63.99 301.48 68.58 295.82 68.58Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M67.5897 67.1099L72.2197 46.2399C72.4997 44.9799 71.3197 43.8699 70.0797 44.2299C61.7397 46.6799 53.4497 49.2999 45.2297 52.1099C37.2497 54.8399 29.4897 57.6999 22.0097 60.6699C21.1997 60.9899 21.3497 62.1799 22.2097 62.2999L65.6797 68.4299C66.5697 68.5499 67.3997 67.9699 67.5897 67.1099Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M463.08 61.5L460.71 50.44C460.32 48.61 459.06 47.09 457.33 46.38C451.83 44.12 446.22 41.91 440.52 39.78C372.03 14.13 297.82 0 231.56 0C165.3 0 91.0797 14.13 22.5997 39.78C16.8997 41.91 11.2997 44.12 5.78974 46.38C4.05974 47.09 2.79974 48.61 2.40974 50.44L0.03974 61.5C-0.25026 62.85 1.09976 63.96 2.36976 63.41C10.5398 59.88 18.9798 56.48 27.6298 53.24C94.5498 28.18 166.97 14.38 231.55 14.38C296.13 14.38 368.56 28.18 435.47 53.24C444.13 56.48 452.57 59.88 460.73 63.41C462.02 63.96 463.37 62.85 463.08 61.5Z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M441.11 60.6601C433.64 57.7001 425.88 54.8301 417.89 52.1001C409.67 49.2901 401.38 46.6701 393.04 44.2201C391.8 43.8601 390.62 44.9601 390.9 46.2301L395.53 67.1001C395.72 67.9701 396.55 68.5401 397.43 68.4201L440.9 62.2901C441.78 62.1701 441.92 60.9801 441.11 60.6601Z"
        fill="url(#paint3_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="231.565"
          y1="19.6972"
          x2="231.565"
          y2="119.36"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#73B1E1" />
          <stop offset="0.1291" stopColor="#67ACDF" />
          <stop offset="0.3438" stopColor="#3FA0DA" />
          <stop offset="0.5843" stopColor="#008FD2" />
          <stop offset="1" stopColor="#0079C1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="46.8728"
          y1="44.1568"
          x2="46.8728"
          y2="68.4379"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#73B1E1" />
          <stop offset="0.1291" stopColor="#67ACDF" />
          <stop offset="0.3438" stopColor="#3FA0DA" />
          <stop offset="0.5843" stopColor="#008FD2" />
          <stop offset="1" stopColor="#0079C1" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="231.565"
          y1="0.0044"
          x2="231.565"
          y2="63.5583"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#73B1E1" />
          <stop offset="0.1291" stopColor="#67ACDF" />
          <stop offset="0.3438" stopColor="#3FA0DA" />
          <stop offset="0.5843" stopColor="#008FD2" />
          <stop offset="1" stopColor="#0079C1" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="416.257"
          y1="44.157"
          x2="416.257"
          y2="68.4381"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#73B1E1" />
          <stop offset="0.1291" stopColor="#67ACDF" />
          <stop offset="0.3438" stopColor="#3FA0DA" />
          <stop offset="0.5843" stopColor="#008FD2" />
          <stop offset="1" stopColor="#0079C1" />
        </linearGradient>
      </defs>
    </svg>
  );
}
